<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-report-bonus-sign pl-8 pr-8">
      <v-row class="mt-11">
        <v-col class="text-left" cols="9">
          <p class="txt-title-report-bonus">
            Firma de bonos / documentos generales 
            <!-- Firmas documentos corporativos -->
          </p>
        </v-col>
        <v-col cols="3">
          <p>
            <v-btn
              class="mt-4"
              @click="download()"
              block
              style="background-color: #fff"
              rounded
              outlined
              color="#466be3"
            >
              <strong> Descargar lista </strong>
              <!-- Icono -->
            </v-btn>
          </p>
        </v-col>
      </v-row>
      <!-- Filtros -->
      <v-row>
        <v-col cols="3">
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date"
                label="Fecha de creación"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="clearDate"
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.date" no-title scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancelar
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(form.date)">
                Seleccionar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            prepend-inner-icon="mdi-filter-outline"
            v-model="form.name"
            :items="listDocuments"
            label="Seleccione el documento"
            item-text="nombre"
            item-value="nombre"
            :disabled="listDocuments.length === 0"

          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            prepend-inner-icon="mdi-filter-outline"
            v-model="form.document_number"
            :items="listUsers"
            label="Seleccione el usuario"
            item-text="full_name"
            item-value="document_number"
            :disabled="listUsers.length === 0"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            prepend-inner-icon="mdi-filter-outline"
            v-model="form.is_sign"
            :items="listStatus"
            label="Seleccione el estado"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <!-- Tabla -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="loading.table"
            :items-per-page="10"
            :headers="headers"
            :items="items"
            item-key="name"
            class="elevation-1 table-report-header report-document__table"
            :footer-props="{ itemsPerPageOptions: [10, 15, 30] }"
            sort-by="created_at"
            sort-desc
          >
            <!-- Sin resultados de busqueda -->
            <template v-slot:no-results>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontraron resultados</b>
              </v-alert>
            </template>
            <!-- Sin Data -->
            <template v-slot:no-data>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            <!-- Loading -->
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            <!-- Nombre -->
            <template v-slot:item.name="{ item }">
              <div class="float-left mt-2 pr-4">
                <img
                  style="border-radius: 120px"
                  width="35"
                  height="35"
                  :src="item.img"
                />
              </div>
              <div>
                <p class="mb-0 mt-2" style="text-transform: capitalize">{{ item.full_name }}</p>
                <p class="mb-0 mb-2">{{ item.document_number }}</p>
              </div>
            </template>
            <!-- Tipo de documento -->
            <template v-slot:item.document="{ item }">
              <v-chip
                :color="item.document === 'multiples_firmas' ? '#466BE3' : getColorStatus('Completado')"
                dark
                small
              >
                <p class="mb-0">
                  <strong>
                    {{ item.document === 'multiples_firmas' ? 'Multi Firmas' : 'Normal' }}
                  </strong>
                </p>
              </v-chip>
            </template>
            <!-- Creado por -->
            <template v-slot:item.fullname_doc="{ item }">
              <p class="mb-0" style="text-transform: capitalize">{{ item.fullname_doc }}</p>
            </template>
            <!-- Fecha de creación -->
            <template v-slot:item.created_at="{ item }">
              <p class="mb-0">{{ formatDate(item.created_at) }}</p>
            </template>
            <!-- estado -->
            <template v-slot:item.activeInactive="{ item }">
              <p class="mb-0 mb-2">Activo</p>
            </template>
            <!-- Firma -->
            <template v-slot:item.status="{ item }">
              <v-chip
                v-if="item.sign"
                :color="getColorStatus('Completado')"
                dark
                small
              >
                <p class="mb-0"><strong> Firmado </strong></p>
              </v-chip>
              <v-chip v-else :color="getColorStatus('Pendiente')" dark small>
                <p class="mb-0"><strong> Sin firma </strong></p>
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div style="display: flex; gap: 8px;">
                <v-tooltip max-width="300" color="#000" bottom v-if="item.status_id != 4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="openModal(item)"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="red"
                      fab
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span> Anular asignación de documento</span>
                </v-tooltip>

                <v-tooltip max-width="300" color="#000" bottom v-if="item.status_id != 4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="seeDocument(item)"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      fab
                    >
                      <v-icon> mdi-eye-outline </v-icon>
                    </v-btn>
                  </template>
                  <span> Visualización del documento</span>
                </v-tooltip>
                <v-tooltip max-width="300" color="#000" bottom v-if="item.status_id == 4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="seeDocumentfirmed(item)"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      fab
                    >
                      <v-icon> mdi-eye-outline </v-icon>
                    </v-btn>
                  </template>
                  <span> Visualización del documento</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="openConfirm" width="410" persistent>
      <v-card style="border-radius: 15px">
        <v-card-title class="pt-6">
          <v-layout justify-center>
            <img
              class="ml-6"
              width="80"
              height="80"
              src="../../assets/icon/alert.svg"
            />
          </v-layout>
          <v-btn
            @click="openConfirm = false"
            color="#466BE3"
            icon
            x-small
            style="display: flex; left: 18px; bottom: 45px"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="document-managmet1__txt-dialog-title">¿Esta seguro</p>
          <p class="document-managmet1__txt-dialog-sms pb-0 mb-0">
            que desea anular el documento de este usuario?
          </p>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row>
            <v-col cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                @click="openConfirm = false"
                outlined
              >
                CERRAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                :loading="loadingBtn"
                @click="deleteDoc()"
              >
                Anular
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      @close="message.dialog = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import color from "@/mixins/color";

export default {
  components: {
    Alert,
  },
  mixins: [color],
  name: "upload",
  data() {
    return {
      openConfirm: false,
      docId: null,
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
      },
      loadingAll: false,
      loadingBtn: false,
      date: null,
      listDocuments: [],
      listUsers: [],
      listStatus: [
        {
          name: "Firmado",
          id: 1,
        },
        {
          name: "Sin firma",
          id: 2,
        },
      ],
      loading: {
        table: false,
      },
      form: {
        name: null,
        document_number: null,
        is_sign: null,
        date: [],
      },
      items: [],
      menu2: false,
      headers: [
        {
          text: "Usuario",
          align: "start",
          value: "name",
          class: "report-document__header",
        },
        {
          text: "Documento",
          value: "nombre",
          class: "report-document__header",
        },
        {
          text: "Tipo de documento",
          value: "document",
          class: "report-document__header",
        },
        {
          text: "Creado por",
          value: "fullname_doc",
          class: "report-document__header",
        },
        {
          text: "Fecha de creación",
          value: "created_at",
          class: "report-document__header",
        },
        {
          text: "Firma",
          value: "status",
          class: "report-document__header",
        },
        {
          text: "Acciones",
          value: "actions",
          class: "report-document__header",
        },
      ],
      token: "",
      name: "",
      infoToken: {
        document: "",
      },
    };
  },
  watch: {
    "form.date": function (n) {
      if (n && n.length > 1) {
        // this.getDocuments();
        this.dinamicFilter();
      }
    },
    "form.name": function (n) {
      this.dinamicFilter();
    },
    "form.document_number": function (n) {
      this.dinamicFilter();
    },
    "form.is_sign": function (n) { 
      this.dinamicFilter();
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const day = d.getDate().toString().padStart(2, '0');
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    openModal(data){
      this.openConfirm = true;
      this.docId =  data.id;
    },
    deleteDoc() {
      let user_id = sessionStorage.getItem("user_id");
      console.log(user_id)
      this.loadingAll = true
      this.openConfirm = false;
      Api.Auth()
        .deleteDocumentsNoFirma(this.token, this.docId,user_id)
        .then((res) => {
          this.loadingAll = false;
          if (res.data.cod == 0) {
            this.message = {
              dialog: true,
              title: "Excelente",
              sms: res.data.message,
              type: "success",
            };
            this.dinamicFilter();
          }
        })
        .catch((error) => {
          this.loadingAll = false;
          if (error.response.status == 404) {
            this.listDocuments = [];
          }
          console.log(error);
        });
    },
    clearDate() {
      this.form.date = [];
      // this.getDocuments();
      this.dinamicFilter();
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    // getDocuments() {
    //   var data = {
    //     init_date: this.form.date[0] != undefined ? this.form.date[0] : null,
    //     final_date: this.form.date[1] != undefined ? this.form.date[1] : null,
    //     type: 'juridica'
    //   };

    //   Api.Auth()
    //     .getDocumentManager(this.token, data)
    //     .then((res) => {
    //       if (res.data.cod == 0) {
    //         this.listDocuments = res.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.status == 404) {
    //         this.listDocuments = [];
    //       }
    //       console.log(error);
    //     });
    // },
    getUsers() {
      Api.Auth()
        .getUsers(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listUsers = res.data.data;
            for (let i = 0; i < this.listUsers.length; i++) {
              this.listUsers[i].user =
                this.listUsers[i].full_name +
                " - " +
                this.listUsers[i].document_number;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    download() {
      this.loadingAll = true;
      let data = {
        name: "",
        document_number: "",
        init_date: null,
        final_date: null,
        type: 'juridica',
        is_sign:""
      };
      if (this.form.name != null || this.form.name != "") {
        data.name = this.form.name;
      }
      if (
        this.form.document_number != null ||
        this.form.document_number != ""
      ) {
        data.document_number = this.form.document_number;
      }
      if (this.form.is_sign != null || this.form.is_sign != "") {
        if (this.form.is_sign != null && this.form.is_sign != "") {
          data.is_sign = this.form.is_sign == 2 ? 0 : 1;
        }
      }

      (data.init_date =
        this.form.date[0] != undefined ? this.form.date[0] : null),
        (data.final_date =
          this.form.date[1] != undefined ? this.form.date[1] : null),
      data.area ='relaciones-laborales';
      Api.Auth()
        .managerDocumentsDownload(this.token, data)
        .then((res) => {
          if (res.data.cod == 0) {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            this.message.title = "Excelente";
            this.message.type = "success";
            window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.message.dialog = true;
          if (error.response.status == 404) {
            this.message.sms =
              "Aun no se han cargado condiciones de comisiones";
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          } else {
            this.message.sms = error.response.data.message;
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          }
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    dinamicFilter() {
      this.loadingAll = true;
      let data = {
        name: "",
        document_number: "",
        init_date: null,
        final_date: null,
        type: 'juridica',
        is_sign:""
      };
      if (this.form.name != null || this.form.name != "") {
        data.name = this.form.name;
      }
      if (
        this.form.document_number != null ||
        this.form.document_number != ""
      ) {
        data.document_number = this.form.document_number;
      }
      if (this.form.is_sign != null || this.form.is_sign != "") {
        if (this.form.is_sign != null && this.form.is_sign != "") {
          data.is_sign = this.form.is_sign == 2 ? 0 : 1;
        }
      }

      (data.init_date =
        this.form.date[0] != undefined ? this.form.date[0] : null),
        (data.final_date =
          this.form.date[1] != undefined ? this.form.date[1] : null),
        data.area ='relaciones-laborales';
        Api.Auth()
          .filterManagerDocuments(this.token, data)
          .then((res) => {
            if (res.data.cod === 0) {
              this.items = res.data.data.res;
              this.listDocuments = res.data.data.docs;
              this.listUsers = res.data.data.users;
            }
          })
          .catch((error) => {
            console.log(error);
            this.items = [];
          })
          .finally(() => {
            this.loadingAll = false;
          });
    },
    seeDocument(item) {
      console.log('Iniciando seeDocument con item:', item);
      this.loadingAll = true;
      
      // Si el documento es de tipo multifirmas, usar el endpoint previsualizarDocumentoSinPassword
      if (item.document === 'multiples_firmas') {
        console.log('Usando endpoint previsualizarDocumentoSinPassword');
        const data = {
          document_sign_id: item.document_sign_id,
          dsu_id: item.id,
          dsu_user_id: item.user_id
        };
        console.log('Datos a enviar para multifirmas:', data);
        
        Api.documentosMultiFirmas()
          .previsualizarDocumentoSinPassword(this.token, data)
          .then((res) => {
            console.log('Respuesta del servidor:', res);
            if (res.data && typeof res.data === 'string') {
              console.log('URL recibida directamente:', res.data);
              this.message = {
                dialog: true,
                title: "Excelente",
                sms: res.message || "Documento generado correctamente",
                type: "success",
                redirect: ""
              };
              this.openDocumentInNewWindow(res.data);
            } else if (res.data && res.data.cod === 0) {
              console.log('Código de respuesta exitoso');
              if (res.data.data) {
                const url = res.data.data;
                console.log('URL a abrir:', url);
                this.openDocumentInNewWindow(url);
              }
            } else {
              console.log('Respuesta no válida:', res);
            }
          })
          .catch((error) => {
            console.error('Error en la llamada a previsualizarDocumentoSinPassword:', error);
            this.message = {
              dialog: true,
              title: "¡Oh no!",
              sms: error.response?.data?.message || "Error al previsualizar el documento",
              type: "error"
            };
          })
          .finally(() => {
            this.loadingAll = false;
            console.log('Finalizando llamada a previsualizarDocumentoSinPassword');
          });
      } else {
        console.log('Usando endpoint previewDocumentsManager');
        const data = {
          documentSignId: item.document_sign_id,
          document_user_id: item.id,
          user_id: item.user_id
        };
        console.log('Datos a enviar para documento normal:', data);
        console.log('Token a usar:', this.token);
        
        Api.Auth()
          .previewDocumentsManager(this.token, data)
          .then((res) => {
            console.log('Respuesta completa del servidor:', res);
            console.log('Estructura de res.data:', typeof res.data, res.data);
            
            if (res.data && typeof res.data === 'string') {
              console.log('URL recibida directamente:', res.data);
              this.message = {
                dialog: true,
                title: "Excelente",
                sms: "Documento generado correctamente",
                type: "success",
                redirect: ""
              };
              console.log('Intentando abrir URL con openDocumentInNewWindow');
              this.openDocumentInNewWindow(res.data);
            } else if (res.data && res.data.cod === 0) {
              console.log('Código de respuesta exitoso');
              const url = res.data.data;
              console.log('URL a abrir:', url);
              this.message = {
                dialog: true,
                title: "Excelente",
                sms: res.data.message || "Documento generado correctamente",
                type: "success",
                redirect: ""
              };
              console.log('Intentando abrir URL con openDocumentInNewWindow');
              this.openDocumentInNewWindow(url);
            } else {
              console.log('Respuesta no válida:', res);
              console.log('Estructura de res.data:', typeof res.data, res.data);
              this.message = {
                dialog: true,
                title: "Error",
                sms: "No se pudo generar el documento",
                type: "error"
              };
            }
          })
          .catch((error) => {
            console.error('Error completo en la llamada a previewDocumentsManager:', error);
            console.error('Error response:', error.response);
            console.error('Error message:', error.message);
            this.message = {
              dialog: true,
              title: "Error",
              sms: error.response?.data?.message || "Error al previsualizar el documento",
              type: "error"
            };
          })
          .finally(() => {
            this.loadingAll = false;
            console.log('Finalizando llamada a previewDocumentsManager');
          });
      }
    },
    openDocumentInNewWindow(url) {
      console.log('Iniciando openDocumentInNewWindow con URL:', url);
      try {
        // Intentar abrir directamente con window.open
        const newWindow = window.open(url, '_blank');
        console.log('Resultado de window.open:', newWindow);
        
        // Si la ventana fue bloqueada, mostrar mensaje al usuario
        if (!newWindow) {
          console.log('El navegador bloqueó la ventana emergente');
          this.message = {
            dialog: true,
            title: "Aviso",
            sms: "El navegador bloqueó la ventana emergente. Por favor, permita las ventanas emergentes para este sitio.",
            type: "warning"
          };
        }
      } catch (error) {
        console.error('Error al abrir la ventana:', error);
      }
    },
    seeDocumentfirmed(item){
      window.open(item.sign, "_blank");
    }
  },
  created() {
    this.getToken();
  },
  mounted() {
    // this.getDocuments();
    // this.getUsers();
    this.dinamicFilter();
  },
};
</script>
<style lang="less">
@import "./../../assets/css/main.less";
.mb-xl-report-bonus-sign {
  display: block;
}

.mb-sm-report-bonus-sign {
  display: none;
}

.txt-title-report-bonus {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "AsapRegular";
}
.document-managmet1__txt-dialog-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #353535;
}
.document-managmet1__txt-dialog-sms {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #898989;
}

.report-document__header {
  color: #353535 !important;
}

tr,
th,
td,
span .report-document__table .text-start .sortable {
  font-size: 15px !important;
  font-family: "RobotoRegular" !important;
}

.report-document__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-report-bonus-sign {
    display: none;
  }
  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-report-bonus-sign {
    display: none;
  }
  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-report-bonus-sign {
    display: none;
  }
  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }

  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-report-bonus-sign {
    display: none;
  }
  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}
</style>
